import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class UserPersonalModel extends BaseModel {
  displayName = '个人信息'
  modelName = 'UserPersonal'
  name = 'UserPersonalModel'

  show (id: number | string) {
    return IOService.get(`user_personal/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`user_personal/${params.id}`, params)
  }
}
