
import { defineComponent } from 'vue'
import RouterService from '@/pk/service/RouterService'
import ModalContainer from './ModalContainer.vue'
import CacheService from '@/pk/service/CacheService'
import IOService from '@/pk/service/IOService'
import MaskContainer from '@/pk/components/MaskContainer.vue'
import TablePagination from '@/pk/components/Table/TablePagination.vue'
import { Plus, Delete, Finished, RefreshLeft, Check } from '@element-plus/icons-vue'
import UIService from '@/pk/service/UIService'
import AppService from '@/pk/service/AppService'
import ModalMixins from '@/pk/components/Modal/ModalMixins'

export default defineComponent({
  mixins: [ModalMixins],
  components: {
    ModalContainer,
    MaskContainer,
    TablePagination,
    Plus,
    Delete,
    Finished,
    RefreshLeft,
    Check
  },
  props: {
    type: {
      type: String,
      default: 'single'
    },
    infoModelName: String,
    infoModelId: [String, Number],
    destroy: Function
  },
  data () {
    return {
      isDisableDeleteBtn: false,
      pagination: {
        page: 1,
        limit: 12,
        total: 0
      },
      uploadParams: {
        infoModelId: this.infoModelId || '',
        marking: RouterService.query('marking'),
        infoModelName: this.infoModelName
      },
      list: [] as any[],
      selection: [] as any[],
      action: AppService.appEnv.apiHost + '/admin/file_manage/upload',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: 'Bearer ' + CacheService.user.get('token')
      },
      uploaded: 0, // 已上传的数量(不论成功与失败)
      uploadElement: null as any
    }
  },
  methods: {
    handleLoad () {
      return this.fetchIndex()
    },
    handleLoadSuccess () {
      this.uploadElement = this.$refs.uploadElement
    },
    getSingleImage () {
      return this.selection[0] || {}
    },
    getMultipleImage () {
      return this.selection
    },
    handleSubmit () {
      if (this.type === 'single') {
        if (this.getSingleImage().id) {
          this.success(this.getSingleImage())
        } else {
          UIService.toast.error('请选择图片')
        }
      } else {
        if (this.getMultipleImage().length > 0) {
          this.success(this.getMultipleImage())
        } else {
          UIService.toast.error('请选择图片')
        }
      }
    },
    onUploaded (res: any, file: any, fileList: any[]) {
      this.uploaded++
      if (this.uploaded === fileList.length) {
        this.fetchIndex()
        this.uploadElement.clearFiles()
      }
    },
    fetchIndex () {
      return IOService.get('/file_manage/index', {
        ...this.uploadParams,
        ...this.pagination
      })
        .then((res) => {
          this.list = res.data.data.map((item: any) => {
            item.active = this.isSelection(item.id)
            return item
          })
          this.pagination.total = res.data.total
          this.changeDeleteBtn()
        })
        .then(() => {
          this.uploaded = 0
        })
    },
    handleChangePage () {
      return this.fetchIndex()
    },
    handleDestroyMore () {
      const ids = this.list.filter((res: any) => res.active).map((res: any) => res.id)
      return IOService.delete('/file_manage/destroy', { ids, infoModelName: this.infoModelName })
        .then(() => {
          const full_urls = this.getDestroyImageFullUrl(ids)
          if (this.destroy) {
            this.destroy(full_urls)
          }
          this.removeSelection(ids)
          if (ids.length === this.list.length) {
            this.pagination.page = this.pagination.page > 1 ? --this.pagination.page : 1
          }
        })
    },
    handleUnSelected () {
      this.list.forEach((res: any) => {
        res.active = false
      })
      this.removeSelection(this.list.map((res) => res.id))
    },
    handleSelectAll () {
      this.list.forEach((res: any) => {
        res.active = true
      })
      this.addSelection(this.list.map((res) => res.id))
    },
    handleSelectImage (v: any) {
      if (this.type === 'single') {
        this.clearSelection()
      }
      v.active = !v.active
      v.active ? this.addSelection([v.id]) : this.removeSelection([v.id])
    },
    addSelection (ids: number[]) {
      this.removeSelection(ids)
      const list = this.list.filter((res) => ids.includes(res.id))
      this.selection = this.selection.concat(list)
      this.changeDeleteBtn()
    },
    removeSelection (ids: number[]) {
      this.selection = this.selection.filter((res) => !ids.includes(res.id))
      this.changeDeleteBtn()
    },
    clearSelection () {
      this.selection = []
      this.list.forEach((res: any) => {
        res.active = false
      })
    },
    isSelection (id: number) {
      return this.selection.some((res) => res.id === id)
    },
    changeDeleteBtn () {
      this.isDisableDeleteBtn = !this.list.some((res: any) => res.active)
    },
    getDestroyImageFullUrl (ids: number[]) {
      return this.selection.filter((res) => ids.includes(res.id)).map((res) => res.full_url)
    }
  }
})
