import { BaseModel } from '@/pk/models/BaseModel'
import IOService from '@/pk/service/IOService'

export default class InfoCheckModel extends BaseModel {
  displayName = '信息审核'
  modelName = 'InfoCheck'
  name = 'InfoCheckModel'

  index (params: { page: number }) {
    return IOService.get('info_check', params)
  }

  show (id: number | string) {
    return IOService.get(`info_check/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`info_check/${params.id}`, params)
  }
}
