import { IOService } from '@/pk/service/IOService'
import RouterService from '@/pk/service/RouterService'

declare module '@/pk/service/IOService' {
  interface IOService {
    resolveError(err: PromiseResult, reject: Function): Promise<any>
  }
}

IOService.prototype.resolveError = (err, reject: Function) => {
  return Promise.resolve()
    .then(() => {
      if (err.code === 401) {
        RouterService.replace('/login')
      }
      reject(err)
    })
}
