import ArticleModel from '@/models/ArticleModel'
import { ModelService } from '@/pk/service/ModelService'
import { markRaw } from 'vue'

declare module '@/pk/service/ModelService' {
  interface ModelService {
    ArticleModel: ArticleModel
  }
}

ModelService.prototype.ArticleModel = markRaw(new ArticleModel())
