import { markRaw } from 'vue'
import { Edit } from '@element-plus/icons-vue'

export default [
  {
    path: '/config',
    component: () => import('@/pk/views/sider.vue'),
    meta: {
      title: '设置'
    },
    children: [
      {
        path: '/config/term',
        component: () => import('@/pk/views/other/term/index.vue'),
        meta: {
          title: '协议列表',
          icon: markRaw(Edit)
        },
        children: [
          {
            path: '/config/term/form',
            component: () => import('@/pk/views/other/term/form.vue'),
            meta: {
              title: '编辑协议',
              isForm: true
            }
          }
        ]
      }
    ]
  }
]
