import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "TableRender" }
const _hoisted_2 = { class: "function-container" }
const _hoisted_3 = { class: "store-btn" }
const _hoisted_4 = {
  key: 1,
  class: "Table-total"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit")!
  const _component_SearchTool = _resolveComponent("SearchTool")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_TablePagination = _resolveComponent("TablePagination")!
  const _component_DataRender = _resolveComponent("DataRender")!
  const _directive_permission = _resolveDirective("permission")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataRender, {
      load: _ctx.handleLoad,
      ref: "drElement",
      onSuccess: _ctx.handleLoadSuccess
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.modelInstance && !!_ctx.modelInstance.store)
              ? _withDirectives((_openBlock(), _createBlock(_component_ButtonSubmit, {
                  key: 0,
                  click: () => _ctx.RouterService.pushForm()
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" 添加" + _toDisplayString(_ctx.modelInstance.displayName), 1)
                  ]),
                  _: 1
                }, 8, ["click"])), [
                  [_directive_permission, _ctx.modelInstance.getPermissionName('store')]
                ])
              : _createCommentVNode("", true)
          ]),
          (_ctx.searchFields && _ctx.isLoading === false)
            ? (_openBlock(), _createBlock(_component_SearchTool, {
                key: 0,
                fields: _ctx.searchFields,
                inModal: _ctx.inModal,
                onReload: _ctx.reload
              }, null, 8, ["fields", "inModal", "onReload"]))
            : _createCommentVNode("", true)
        ]),
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          ref: "table",
          border: "",
          data: _ctx.list,
          "row-key": "id",
          style: {"width":"100%"},
          "span-method": _ctx.spanMethod,
          "max-height": _ctx.innerMaxHeight,
          "empty-text": _ctx.emptyText,
          "default-expand-all": _ctx.defaultExpandAll,
          onSelectionChange: _ctx.handleSelectionChange
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["data", "span-method", "max-height", "empty-text", "default-expand-all", "onSelectionChange"])), [
          [_directive_loading, _ctx.isLoading]
        ]),
        _createElementVNode("template", null, [
          (_ctx.isShowPagination)
            ? (_openBlock(), _createBlock(_component_TablePagination, {
                key: 0,
                onChangePage: _ctx.handlePageLoad,
                pagination: _ctx.pagination
              }, null, 8, ["onChangePage", "pagination"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, " 总共：" + _toDisplayString(_ctx.pagination.total) + " 项 ", 1))
        ])
      ]),
      _: 3
    }, 8, ["load", "onSuccess"])
  ]))
}