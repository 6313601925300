
import ToolService from '@/pk/service/ToolService'
import { defineComponent } from 'vue'
import TableMixins from './TableMixins'

export default defineComponent({
  mixins: [TableMixins],
  props: {
    type: String,
    options: Array
  },
  inject: ['TableRender'],
  methods: {
    getValue (row: any) {
      const value = ToolService.getDeepValue(this.prop, row)
      if (value) {
        if (this.type === 'city') {
          return ToolService.getCityName(value)
        } else {
          const paths = ToolService.getAncestorsAndSelf(value, this.options as any[], this.props.value)
          return paths.map((res: any) => res[this.props.label]).join('/')
        }
      } else {
        return '--'
      }
    }
  }
})
