
import { defineComponent } from 'vue'
import ToolService from '@/pk/service/ToolService'
import ChartService from '@/pk/service/ChartService'

export default defineComponent({
  props: {
    type: String,
    title: String,
    code: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      category: [] as string[],
      componentName: '',
      values: [],
      search: {
        date: ToolService.formatDate(undefined, 'Y-m'),
        type: 'month'
      }
    }
  },
  computed: {
    dataElement (): any {
      return this.$refs.dataElement
    }
  },
  created () {
    if (this.type === 'line') {
      this.componentName = 'ChartLine'
    }
  },
  methods: {
    handleLoad () {
      return Promise.resolve()
        .then(() => {
          if (this.search.type === 'month') {
            return ChartService.getCurrentMonthData(this.search.date)
          } else {
            return ChartService.getCurrentYearData(this.search.date)
          }
        })
        .then((res) => {
          this.category = res.data.category
          this.values = res.data.values
        })
    },
    handleChange (params: { date: string; type: string }) {
      Object.assign(this.search, params)
      this.dataElement.reload()
    }
  }
})
