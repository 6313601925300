
import { defineComponent } from 'vue'

// 以会员板块举例，会员列表路由层级是3级，那么进入form表单是第四级，列表页没有router-view组件没法进入第四级，所以一般会在列表页包一层这个组件
export default defineComponent({
  props: {
    deep: {
      type: Number,
      default: 3
    }
  },
  watch: {
    '$route' () {
      if (this.$route.matched[this.deep - 1]) {
        this.DataRenderElement.reload()
      }
    }
  },
  computed: {
    DataRenderElement (): any {
      return this.$refs.DataRenderElement
    }
  }
})
