
import { PromiseResult } from '@/pk/service/IOService'
import { defineComponent } from 'vue'

const STATUS = {
  INIT: 0, // 占位(暂未用到)
  LOADING: 1,
  SUCCESS: 2,
  WAIT: 3,
  ERROR: -1
}

export default defineComponent({
  props: {
    load: {
      type: Function,
      default: () => Promise.resolve()
    }
  },
  data () {
    return {
      STATUS,
      status: STATUS.INIT,
      result: {
        text: '数据错误'
      }
    }
  },
  mounted () {
    this.initLoad()
  },
  methods: {
    initLoad () {
      this.status = STATUS.LOADING
      return this.doOnLoad()
    },
    doOnLoad () {
      return this.load()
        .then(() => {
          this.status = STATUS.SUCCESS
          this.$nextTick(() => {
            this.$emit('success')
          })
        })
        .catch((res: PromiseResult) => {
          if (res && res.message) {
            this.result.text = res.message
          }
          this.status = STATUS.ERROR
        })
    },
    reload () {
      return this.initLoad()
    }
  }
})
