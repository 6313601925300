import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueNeditorWrap = _resolveComponent("VueNeditorWrap")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.field.label,
    prop: _ctx.field.prop,
    rules: _ctx.field.rule,
    class: "FormEditor"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_VueNeditorWrap, {
        value: _ctx.innerValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        config: _ctx.editorConfig,
        destroy: false,
        onReady: _ctx.editorReady,
        onBeforeInit: _ctx.editorBeforeInit,
        onInput: _ctx.handleInput,
        ref: "NEditorElement"
      }, null, 8, ["value", "config", "onReady", "onBeforeInit", "onInput"])
    ]),
    _: 1
  }, 8, ["label", "prop", "rules"]))
}