
import { ITableColumnAction } from '@/pk/interface/common'
import { defineComponent, inject } from 'vue'
import TableMixins from './TableMixins'

export default defineComponent({
  mixins: [TableMixins],
  props: {
    buttons: {
      type: Array as () => ITableColumnAction[]
    },
    fixed: {
      type: String,
      default: 'right'
    }
  },
  setup () {
    const TableRender = inject<any>('TableRender')
    return {
      TableRender
    }
  },
  methods: {
    handleRefresh () {
      return this.TableRender.reload()
    }
  }
})
