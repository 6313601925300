
import { defineComponent } from 'vue'
import { PromiseResult } from '@/pk/service/IOService'
import UIService from '@/pk/service/UIService'

export default defineComponent({
  inheritAttrs: false,
  props: {
    click: {
      type: Function,
      default: () => Promise.resolve()
    },
    type: {
      type: String,
      default: 'primary'
    },
    long: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    handleSubmit () {
      if (this.loading) return
      this.loading = true
      const result = this.click()
      if (result && typeof result.then === 'function') {
        result
          .then((res: PromiseResult) => {
            if (res && res.message) {
              UIService.toast.success(res.message)
            }
            this.loading = false
          })
          .catch((res: PromiseResult) => {
            if (res) {
              if (res.code === 422) {
                const errList = Object.values(res.data) as string[][]
                const messageHTML = errList.map((item) => item.join('<br>')).join('<br>')
                UIService.toast.error('<div style="line-height: 1.4">' + messageHTML + '</div>')
              } else if (res.message) {
                UIService.toast.error(res.message)
              }
            }
            this.loading = false
          })
      } else {
        this.loading = false
      }
    }
  }
})
