
import { IFormFieldItem } from '@/pk/interface/common'
import { OptionItem } from '@/pk/service/OptionService'
import ToolService from '@/pk/service/ToolService'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: {
      type: Object as () => IFormFieldItem,
      required: true
    },
    modelValue: {
      type: [Array as () => string[] | number[]],
      default: ''
    }
  },
  watch: {
    innerValue (newVal) {
      this.$emit('update:modelValue', newVal)
    }
  },
  data () {
    return {
      props: {
        value: 'value',
        label: 'display_name'
      },
      options: [] as OptionItem[],
      innerValue: this.modelValue
    }
  },
  created () {
    Object.assign(this.props, this.field.props || {})
    this.options = ToolService.checkOptionObjToArray(this.field.options)
  }
})
