import { BaseModel } from '@/pk/models/BaseModel'
import IOService from '@/pk/service/IOService'

export default class ArticleModel extends BaseModel {
  displayName = '文章'
  modelName = 'Article'
  formPath = 'article/form'
  name = 'ArticleModel'

  index (params: { page: number }) {
    return IOService.get('article', params)
  }

  store (params: any) {
    return IOService.post('article', params)
  }

  show (id: number | string) {
    return IOService.get(`article/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`article/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`article/${id}`)
  }
}
