
import { defineComponent, inject } from 'vue'
import UIService from '@/pk/service/UIService'
import ImageMaskContainer from '../ImageMaskContainer.vue'
import ModalFileManageService from '../Modal/ModalFileManageService'
import FormMixins from './FormMixins'
import { Plus } from '@element-plus/icons-vue'
import AppService from '@/pk/service/AppService'
import ModelService from '@/pk/service/ModelService'
export default defineComponent({
  mixins: [FormMixins],
  components: {
    ImageMaskContainer,
    Plus
  },
  data () {
    return {
      ModalFileManageService: new ModalFileManageService(),
      innerValue: (this.modelValue || '') as string
    }
  },
  computed: {
    formItemElement (): any {
      return this.$refs.formItemElement
    }
  },
  setup () {
    const FormRender = inject<any>('FormRender')
    return {
      FormRender
    }
  },
  methods: {
    handleChangeModal () {
      if (!ModelService[this.FormRender.model]) {
        return Promise.reject(new Error('需要一个模型'))
      }
      return this.ModalFileManageService.open({
        debug: AppService.appEnv.env === 'dev',
        infoModelName: ModelService[this.FormRender.model].modelName,
        infoModelId: this.FormRender.form.id
      })
        .then((data: { full_url: string }) => {
          this.innerValue = data.full_url
          this.formItemElement.clearValidate()
        })
        .catch((err) => UIService.toast.error(err.message))
    },
    handleDestroy (urls: string[]) {
      if (urls.includes(this.innerValue as string)) {
        this.innerValue = ''
      }
    }
  }
})
