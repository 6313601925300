import AdminLogModel from '@/pk/models/Log/AdminLogModel'
import AdminUserModel from '../models/User/AdminUserModel'
import UserBillModel from '../models/User/UserBillModel'
import UserControlModel from '../models/User/UserControlModel'
import UserCouponModel from '../models/User/UserCouponModel'
import UserEnterpriseModel from '../models/User/UserEnterpriseModel'
import UserInfoAuthModel from '../models/User/UserInfoAuthModel'
import UserModel from '../models/User/UserModel'
import UserPersonalModel from '../models/User/UserPersonalModel'
import UserRoleModel from '../models/User/UserRoleModel'
import TaskModel from '../models/Task/TaskModel'
import TaskRecordModel from '../models/Task/TaskRecordModel'
import AdModel from '../models/Other/AdModel'
import NoticeModel from '../models/Other/NoticeModel'
import TermModel from '../models/Other/TermModel'
import InfoCheckModel from '../models/Info/InfoCheckModel'
import CouponTemplateModel from '../models/Coupon/CouponTemplateModel'
import { markRaw } from 'vue'

export class ModelService {
  [key: string]: any

  AdminLogModel = markRaw(new AdminLogModel())
  AdminUserModel = markRaw(new AdminUserModel())
  UserBillModel = markRaw(new UserBillModel())
  UserControlModel = markRaw(new UserControlModel())
  UserCouponModel = markRaw(new UserCouponModel())
  UserEnterpriseModel = markRaw(new UserEnterpriseModel())
  UserInfoAuthModel = markRaw(new UserInfoAuthModel())
  UserModel = markRaw(new UserModel())
  UserPersonalModel = markRaw(new UserPersonalModel())
  UserRoleModel = markRaw(new UserRoleModel())
  TaskModel = markRaw(new TaskModel())
  TaskRecordModel = markRaw(new TaskRecordModel())
  AdModel = markRaw(new AdModel())
  NoticeModel = markRaw(new NoticeModel())
  TermModel = markRaw(new TermModel())
  InfoCheckModel = markRaw(new InfoCheckModel())
  CouponTemplateModel = markRaw(new CouponTemplateModel())
}

export default new ModelService()
