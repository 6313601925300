import { markRaw } from 'vue'
import { MessageBox, Money, PriceTag, Aim } from '@element-plus/icons-vue'

export default [
  {
    path: '/operation',
    component: () => import('@/pk/views/sider.vue'),
    meta: {
      title: '运营管理'
    },
    children: [
      {
        path: '/operation/notice',
        component: () => import('@/pk/views/other/notice/index.vue'),
        meta: {
          title: '平台公告',
          icon: markRaw(PriceTag)
        },
        children: [
          {
            path: '/operation/notice/form',
            component: () => import('@/pk/views/other/notice/form.vue'),
            meta: {
              title: '编辑公告',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/operation/coupon_template',
        component: () => import('@/views/operation/coupon/template/index.vue'),
        meta: {
          title: '优惠券模板',
          icon: markRaw(MessageBox)
        },
        children: [
          {
            path: '/operation/coupon_template/form',
            component: () => import('@/views/operation/coupon/template/form.vue'),
            meta: {
              title: '编辑优惠券模板',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/operation/coupon',
        component: () => import('@/views/operation/coupon/index.vue'),
        meta: {
          title: '优惠券列表',
          icon: markRaw(Money)
        }
      },
      {
        path: '/operation/task',
        component: () => import('@/pk/views/operation/task/index.vue'),
        meta: {
          title: '任务管理',
          icon: markRaw(Aim)
        },
        children: [
          {
            path: '/operation/task/form',
            component: () => import('@/pk/views/operation/task/form.vue'),
            meta: {
              title: '编辑任务',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/operation/task-record',
        component: () => import('@/pk/views/operation/task-record/index.vue'),
        meta: {
          title: '任务记录',
          icon: markRaw(Aim)
        }
      }
    ]
  }
]
