import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "TableButtons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit")!
  const _component_ArrowDown = _resolveComponent("ArrowDown")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerButtons, (v, key) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (v.name === '删除' && _ctx.handleOnShow(v))
          ? (_openBlock(), _createBlock(_component_ButtonDelete, {
              key: 'a' + key,
              type: "danger",
              size: v.size || 'small',
              plain: v.plain || false,
              click: () => _ctx.handleDestroy(v)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(v.name), 1)
              ]),
              _: 2
            }, 1032, ["size", "plain", "click"]))
          : (v.name === '编辑' && _ctx.handleOnShow(v))
            ? (_openBlock(), _createBlock(_component_ButtonSubmit, {
                key: 'b' + key,
                type: v.type || 'primary',
                size: v.size || 'small',
                plain: v.plain || false,
                click: () => _ctx.handleEdit(v)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(v.name), 1)
                ]),
                _: 2
              }, 1032, ["type", "size", "plain", "click"]))
            : (v.name === '更多')
              ? (_openBlock(), _createBlock(_component_el_popover, {
                  "popper-class": "TableButtons-popper",
                  key: 'd' + key,
                  width: "100px",
                  placement: "bottom"
                }, {
                  reference: _withCtx(() => [
                    _createVNode(_component_ButtonSubmit, {
                      link: "",
                      type: v.type || 'primary',
                      size: v.size || 'small'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 更多"),
                        _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ArrowDown)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["type", "size"])
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.children, (item, itemKey) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "more-btns",
                        key: itemKey
                      }, [
                        (item.name === '删除' && _ctx.handleOnShow(item))
                          ? (_openBlock(), _createBlock(_component_ButtonDelete, {
                              key: 0,
                              link: "",
                              size: item.size || 'default',
                              click: () => _ctx.handleDestroy(item)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["size", "click"]))
                          : (_ctx.handleOnShow(item))
                            ? (_openBlock(), _createBlock(_component_ButtonSubmit, {
                                key: 1,
                                link: "",
                                click: () => item.onClick(_ctx.row),
                                size: item.size || 'default'
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["click", "size"]))
                            : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]),
                  _: 2
                }, 1024))
              : (_ctx.handleOnShow(v))
                ? (_openBlock(), _createBlock(_component_ButtonSubmit, {
                    key: 'e' + key,
                    type: v.type || 'warning',
                    size: v.size || 'small',
                    click: () => _ctx.handleClick(v)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(v.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["type", "size", "click"]))
                : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}