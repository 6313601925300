import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "TablePagination" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_pagination, {
      background: "",
      layout: "prev, pager, next, sizes, total",
      onSizeChange: _ctx.changePageSize,
      onCurrentChange: _ctx.changePage,
      "current-page": _ctx.pagination.page,
      "page-size": _ctx.pagination.limit,
      "page-sizes": _ctx.pageSizes,
      total: _ctx.pagination.total
    }, null, 8, ["onSizeChange", "onCurrentChange", "current-page", "page-size", "page-sizes", "total"])
  ]))
}