import { IFormFieldItem } from '@/pk/interface/common'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: {
      type: Object as () => IFormFieldItem,
      required: true
    },
    modelValue: {
      type: [String, Number, Object, Array]
    }
  },
  watch: {
    modelValue (val) {
      this.innerValue = val
    },
    innerValue (val) {
      this.$emit('update:modelValue', val)
    }
  },
  data () {
    return {
      innerValue: (this.modelValue === undefined || this.modelValue === null) ? '' : this.modelValue
    }
  }
})
