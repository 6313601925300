
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    treeList: {
      type: Array,
      default: () => []
    },
    defaultCheckedKeys: {
      type: Array,
      default: () => []
    },
    nodeKey: {
      type: String,
      default: 'name'
    },
    maxDeep: {
      type: Number,
      default: 2
    },
    checkStrictly: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    treeElement (): any {
      return this.$refs.treeElement
    }
  },
  data () {
    return {
      innerValue: this.modelValue
    }
  },
  mounted () {
    this.treeElement.setCheckedKeys(this.innerValue)
  },
  methods: {
    getCheckedNodeIds () {
      const checkedKeys = this.treeElement.getCheckedKeys()
      return checkedKeys
    },
    handleCheck (res: any, item: any) {
      this.$emit('update:modelValue', item.checkedKeys)
    }
  }
})
