
import OptionService, { OptionItem } from '@/pk/service/OptionService'
import ToolService from '@/pk/service/ToolService'
import { defineComponent } from 'vue'
import TableMixins from './TableMixins'

export default defineComponent({
  mixins: [TableMixins],
  inject: ['TableRender'],
  props: {
    bool: Boolean,
    colors: {
      type: Object,
      default: () => {
        return {}
      }
    },
    options: {
      type: [Array as () => OptionItem[], Object as () => { [key: string]: number }]
    }
  },
  data () {
    return {
      innerOptions: [] as OptionItem[]
    }
  },
  created () {
    if (this.options) {
      this.innerOptions = ToolService.checkOptionObjToArray(JSON.parse(JSON.stringify(this.options)))
    } else if (this.bool) {
      this.innerOptions = OptionService.getBoolOptions()
    }
  },
  methods: {
    getValue (row: any) {
      const value = this.__getValue(row)
      if (Array.isArray(value)) {
        return value.map((v) => {
          const item = this.innerOptions.find((res) => res[this.props.value] === v)
          return item ? item[this.props.label] : value
        }).join()
      } else {
        const item = this.innerOptions.find((res) => res[this.props.value] === value)
        return item ? item[this.props.label] : value
      }
    },
    getColor (row: any) {
      const value = this.__getValue(row)
      return OptionService.getColor(this.colors[value])
    },
    __getValue (row: any) {
      const value = ToolService.getDeepValue(this.prop, row)
      return this.bool && !value ? 0 : value
    }
  }
})
