
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'

export default defineComponent({
  mixins: [FormMixins],
  data () {
    return {
      min: undefined,
      max: undefined
    }
  },
  created () {
    const maxItem = (this.field.rule || []).find((res: any) => res.max)
    if (maxItem) {
      this.max = maxItem.max
    }
    const minItem = (this.field.rule || []).find((res: any) => res.min)
    if (minItem) {
      this.min = minItem.min
    }
    this.innerValue = this.innerValue || 0
  }
})
