import axios from 'axios'
import CacheService from '@/pk/service/CacheService'
import AppService from './AppService'
import AuthService from './AuthService'

export interface PromiseResult {
  message: string;
  data: any;
  code?: number;
  status?: string;
}

export class IOService {
  private get appApi () {
    return AppService.appEnv.apiHost + '/admin'
  }

  constructor () {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    axios.defaults.headers.common.Accept = 'application/json'
  }

  get (url: string, data?: object) {
    return this.ajax({
      url,
      method: 'get',
      params: data
    })
  }

  post (url: string, data: object) {
    return this.ajax({
      url,
      method: 'post',
      data
    })
  }

  put (url: string, data: object) {
    return this.ajax({
      url,
      method: 'put',
      data
    })
  }

  delete (url: string, data?: object) {
    return this.ajax({
      url,
      method: 'delete',
      data
    })
  }

  private ajax (params: { url: string, method: string, data?: object, params?: object }): Promise<PromiseResult> {
    axios.defaults.baseURL = this.appApi
    if (CacheService.user.get('token')) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + CacheService.user.get('token')
    }
    if (params.method === 'post') {
      params.data = Object.assign({}, params.data || {}, { _env: AppService.appEnv })
    }

    return new Promise((resolve, reject) => {
      axios(params)
        .then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            if (params.url === 'auth/token') {
              AuthService.updateData(res.data)
            }
            resolve(res)
          } else {
            reject(res)
          }
        })
        .catch((err) => {
          const innerError = err.response ? err.response.data : err
          if (innerError.message === 'Unauthenticated.') {
            innerError.code = 401
            innerError.status = 'error'
            innerError.data = {}
            innerError.message = '请先登录'
          }
          if (this.resolveError) {
            return this.resolveError(innerError, reject)
          }
        })
    })
  }
}

export default new IOService()
