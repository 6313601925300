export abstract class BaseModel {
  abstract modelName: string // 模型标识
  abstract displayName: string // 模型名称
  abstract name: string // 作用于ModelService中的key
  controllerName!: string // 控制器名称
  formPath!: string // 表单模板路径

  public getControllerName () {
    return this.controllerName ? this.controllerName : this.modelName + 'Controller'
  }

  public getPermissionName (name: string) {
    return name.includes('@') ? name : this.getControllerName() + '@' + name
  }
}
