
import OptionService from '@/pk/service/OptionService'
import { defineComponent, inject } from 'vue'
import IOService from '@/pk/service/IOService'
import FormMixins from './FormMixins'

export default defineComponent({
  mixins: [FormMixins],
  props: {
    type: String,
    url: String
  },
  data () {
    return {
      paths: [] as number[][] | string[][],
      props: {
        expandTrigger: 'hover',
        label: 'display_name',
        value: 'id',
        multiple: true,
        emitPath: false
      },
      innerUrl: this.url
    }
  },
  created () {
    this.setInnerValue()
    this.handleLoad()
      .then(() => {
        Object.assign(this.props, this.field.props)
      })
  },
  setup () {
    const FormRender = inject<any>('FormRender')
    return {
      FormRender
    }
  },
  methods: {
    setInnerValue () {
      this.innerValue = (this.modelValue as any || []).map((res: number | { id: number }) => {
        if (typeof res === 'object' && res.id) {
          return res.id
        } else {
          return res
        }
      })
    },
    handleLoad () {
      return Promise.resolve()
        .then(() => {
          if (this.type === 'city') {
            this.field.props = {
              label: 'name',
              value: 'id'
            }
            return OptionService.getAreaOptions()
          } else if (this.innerUrl) {
            return IOService.get(this.innerUrl)
              .then((res) => res.data || [])
          }
        })
        .then((data) => {
          if (data) {
            this.field.options = data
          }
        })
    },
    handleChange () {
      this.$nextTick(() => {
        this.FormRender.validateField(this.field.prop)
      })
    }
  }
})
