import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class UserRoleModel extends BaseModel {
  displayName = '会员角色'
  modelName = 'UserRole'
  name = 'UserRoleModel'
  index (params: any) {
    return IOService.get('user_role', params)
  }

  store (params: any) {
    return IOService.post('user_role', params)
  }

  show (id: number | string) {
    return IOService.get(`user_role/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`user_role/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`user_role/${id}`)
  }

  getAssignablePermissionTree (params: { guardName: string }) {
    return IOService.get('user_role/api/getAssignablePermissionTree', params)
  }
}
