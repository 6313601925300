import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class AdminUserModel extends BaseModel {
  displayName = '后台用户'
  modelName = 'User'
  name = 'AdminUserModel'

  index (params: { page: number }) {
    return IOService.get('admin_user', params)
  }

  store (params: any) {
    return IOService.post('admin_user', params)
  }

  show (id: number | string) {
    return IOService.get(`admin_user/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`admin_user/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`admin_user/${id}`)
  }

  getAssignableRoles () {
    return IOService.get('admin_user/api/getAssignableRoles')
  }
}
