import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class UserInfoAuthModel extends BaseModel {
  displayName = '会员认证'
  modelName = 'UserInfoAuth'
  name = 'UserInfoAuthModel'

  index (params: { infoModelName: string, page: string; [x: string]: any }) {
    return IOService.get('user_info_auth', params)
  }

  show (id: number | string) {
    return IOService.get(`user_info_auth/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`user_info_auth/${params.id}`, params)
  }
}
