import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class UserCouponModel extends BaseModel {
  displayName = '优惠券'
  modelName = 'UserCoupon'
  name = 'UserCouponModel'
  index (params: any) {
    return IOService.get('user_coupon', params)
  }
}
