import { markRaw } from 'vue'
import { Medal, Notebook, User } from '@element-plus/icons-vue'
import RouterService from '@/pk/service/RouterService'

export default [
  {
    path: '/admin',
    component: () => import('@/pk/views/sider.vue'),
    meta: {
      title: '后台管理'
    },
    children: [
      {
        path: '/admin/user',
        component: () => import('@/views/admin/user/index.vue'),
        meta: {
          title: '后台成员',
          icon: markRaw(User)
        },
        children: [
          {
            path: '/admin/user/form',
            component: () => import('@/views/admin/user/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑后台成员' : '添加后台成员'
              },
              isForm: true
            }
          }
        ]
      },
      {
        path: '/admin/role',
        component: () => import('@/views/admin/role/index.vue'),
        meta: {
          title: '后台角色',
          icon: markRaw(Medal)
        },
        children: [
          {
            path: '/admin/role/form',
            component: () => import('@/views/admin/role/form.vue'),
            meta: {
              title: '编辑后台角色',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/admin/log',
        component: () => import('@/pk/views/admin/log/index.vue'),
        meta: {
          title: '操作日志',
          icon: markRaw(Notebook)
        }
      }
    ]
  }
]
