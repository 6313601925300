
import { defineComponent } from 'vue'

interface ButtonItem {
  text: string;
  active: boolean;
  type: string;
  valueFormat: string;
}

interface Search {
  date: string;
  type: string;
  text: string;
}

export default defineComponent({
  props: {
    search: {
      type: Object as () => Search,
      required: true
    }
  },
  data () {
    return {
      buttons: [
        {
          text: '按日',
          active: this.search.type === 'month',
          type: 'month',
          valueFormat: 'YYYY-MM'
        },
        {
          text: '按月',
          active: this.search.type === 'year',
          type: 'year',
          valueFormat: 'YYYY'
        }
      ] as ButtonItem[],
      valueFormat: 'YYYY-MM',
      pickerOptions: {
        disabledDate: (time: Date) => {
          return time.getTime() > (new Date()).getTime()
        }
      }
    }
  },
  methods: {
    handleChangeButton (v: ButtonItem) {
      this.buttons.forEach((res) => {
        if (res.text === v.text) {
          res.active = true
        } else {
          res.active = false
        }
        this.valueFormat = v.valueFormat
        this.search.type = v.type
        this.search.date = ''
      })
    },
    handleChangeDate (date: string) {
      const item = this.buttons.find((res) => res.active) as ButtonItem
      this.search.type = item.type
      this.search.date = date
      this.$emit('change')
    }
  }
})
