import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(_component_el_table_column, {
    prop: _ctx.prop,
    label: _ctx.label,
    width: _ctx.width,
    "min-width": _ctx.minWidth,
    align: _ctx.TableRender.align || _ctx.align,
    "class-name": _ctx.className
  }, {
    default: _withCtx((scope) => [
      _createTextVNode(_toDisplayString(_ctx.getValue(scope.row)), 1)
    ]),
    _: 1
  }, 8, ["prop", "label", "width", "min-width", "align", "class-name"]))
}