import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class AdModel extends BaseModel {
  displayName = '广告'
  modelName = 'Ad'
  name = 'AdModel'

  index (params: { page: number }) {
    return IOService.get('ad', params)
  }

  store (params: any) {
    return IOService.post('ad', params)
  }

  show (id: number | string) {
    return IOService.get(`ad/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`ad/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`ad/${id}`)
  }
}
