
import { IPagination, ISearchFields } from '@/pk/interface/common'
import RouterService from '@/pk/service/RouterService'
import { defineComponent } from 'vue'
import TablePagination from './TablePagination.vue'
import SearchTool from '../SearchTool/SearchTool.vue'
import QueryString from 'qs'
import { PromiseResult } from '@/pk/service/IOService'
import ModelService from '@/pk/service/ModelService'

export default defineComponent({
  components: {
    TablePagination,
    SearchTool
  },
  props: {
    load: Function,
    beforeLoad: {
      type: Function,
      default: () => Promise.resolve()
    },
    searchFields: {
      type: Array as () => ISearchFields[]
    },
    spanMethod: Function,
    maxHeight: Number,
    align: String,
    defaultExpandAll: Boolean,
    model: String,
    query: {
      type: Object,
      default: () => {
        return {}
      }
    },
    inModal: Boolean
  },
  provide () {
    return {
      TableRender: {
        align: this.align,
        reload: this.reload,
        model: this.model
      }
    }
  },
  data () {
    return {
      innerMaxHeight: 0,
      RouterService,
      isLoading: false,
      pagination: {
        page: Number(RouterService.query('page')) || 1,
        limit: Number(RouterService.query('limit')) || 10,
        total: 0
      } as IPagination,
      list: [] as any[],
      isShowPagination: false,
      searchParams: { _search: Object.values(QueryString.parse(RouterService.query('_search') as string)) },
      emptyText: '暂无数据',
      modelInstance: null as any
    }
  },
  computed: {
    drElement (): any {
      return this.$refs.drElement
    },
    tableElement (): any {
      return this.$refs.table
    }
  },
  methods: {
    init () {
      return Promise.resolve()
        .then(() => {
          if (this.model) {
            this.modelInstance = ModelService[this.model]
          }
        })
    },
    handleLoad (params = {}) {
      if (this.drElement) {
        this.innerMaxHeight = this.maxHeight ? this.maxHeight : document.documentElement.clientHeight - (this.drElement.$el.offsetTop + 180)
      }
      return this.init()
        .then(() => {
          if (!this.isLoading) {
            this.isLoading = true
            return Promise.resolve()
              .then(() => {
                if (this.beforeLoad) {
                  return this.beforeLoad()
                }
              })
              .then(() => {
                if (this.load) {
                  return this.load({
                    ...this.query,
                    ...this.pagination,
                    ...this.searchParams,
                    ...params
                  })
                } else if (this.modelInstance) {
                  return this.modelInstance.index({
                    ...this.query,
                    ...this.pagination,
                    ...this.searchParams,
                    ...params
                  })
                }
              })
              .then((res: PromiseResult) => {
                if (Array.isArray(res)) {
                  this.list = res
                  this.pagination.total = this.list.length
                } else if (res && Array.isArray(res.data)) {
                  this.list = res.data
                  this.pagination.total = this.list.length
                } else if (res && res.data && res.data.data) {
                  this.isShowPagination = true
                  this.pagination.total = res.data.total
                  this.list = res.data.data
                }
                this.isLoading = false
                return res
              })
              .catch((err: any) => {
                this.isLoading = false
                if (err && err.message) {
                  this.emptyText = err.message
                }
                return Promise.reject(err)
              })
          }
        })
    },
    handleLoadSuccess () {
      this.$emit('loadSuccess')
    },
    reload (params: any) {
      this.list = []
      return this.handleLoad(params)
    },
    handlePageLoad () {
      RouterService.replace(RouterService.getPath(), { ...RouterService.getRouteQuery(), page: this.pagination.page, limit: this.pagination.limit })
    },
    handleSelectionChange (values: any) {
      this.$emit('selection-change', values)
    },
    toggleRowSelection (row: any, bool?: boolean) {
      const item = this.list.find((res) => res.id === row.id)
      if (item) {
        this.tableElement.toggleRowSelection(item, bool)
      }
    },
    clearSelection () {
      this.tableElement.clearSelection()
    }
  }
})
