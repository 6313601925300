
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    src: String,
    zip: String,
    fit: {
      type: String,
      default: 'cover'
    },
    width: String,
    height: String
  },
  computed: {
    styles (): any {
      return {
        width: this.width,
        height: this.height
      }
    }
  },
  data () {
    return {
      innerSrc: this.src
    }
  },
  methods: {
    handleLoad (e: Event) {
      this.$emit('load', e)
    }
  }
})
