
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'

interface InnerRule {
  required?: boolean;
  validator?: Function;
  message?: string;
}

export default defineComponent({
  mixins: [FormMixins],
  props: {
    type: {
      type: String,
      default: 'daterange'
    },
    minValue: String,
    maxValue: String
  },
  watch: {
    innerValue (val: string[]) {
      val = val || []
      const [minValue, maxValue] = val
      this.$emit('update:minValue', minValue)
      this.$emit('update:maxValue', maxValue)
    },
    minValue (val) {
      if (!val) {
        this.innerValue = []
        this.$emit('update:minValue', '')
        this.$emit('update:maxValue', '')
      }
    }
  },
  data () {
    return {
      valueFormat: 'YYYY-MM-DD',
      innerRules: [] as InnerRule[]
    }
  },
  created () {
    if (this.type === 'monthrange') {
      this.valueFormat = 'YYYY-MM'
    }
    this.innerValue = (this.minValue && this.maxValue) ? [this.minValue, this.maxValue] : []
    this.initRequiredRule()
  },
  methods: {
    validateRequired (rule: any, value: number, callback: Function) {
      if (this.minValue && this.maxValue) {
        callback()
      } else {
        const text = `请选择${this.field.label}`
        callback(new Error(text))
      }
    },
    initRequiredRule () {
      const requiredIndex = (this.field.rule || []).findIndex((res) => res.required)
      if (requiredIndex > -1 && this.field.rule) {
        this.field.rule.splice(requiredIndex, 1, { required: true, type: 'array', trigger: 'change', validator: this.validateRequired })
      }
    }
  }
})
