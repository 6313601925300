import { markRaw } from 'vue'
import ModalAbstract from './ModalAbstract'

export default class ModalService extends ModalAbstract {
  public name = 'ModalService'
  constructor (component: any) {
    super()
    this.component = markRaw(component)
  }
}
