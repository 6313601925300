import { defineComponent, shallowRef } from 'vue'
import echarts from './echarts'

export default defineComponent({
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    category: {
      type: Array as () => string[],
      required: true
    },
    code: {
      type: String,
      required: true
    }
  },
  watch: {
    category () {
      this.chart.setOption(this.getOptions())
    },
    modelValue (val) {
      this.innerValue = val
    }
  },
  mounted () {
    const el = this.$el.querySelector('.report-' + this.code)
    this.chart = echarts.init(el)
    this.chart.setOption(this.getOptions())
  },
  data () {
    return {
      chart: shallowRef(null) as any,
      innerValue: this.modelValue
    }
  },
  methods: {
    getOptions () {
      //
    }
  }
})
