import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class TaskModel extends BaseModel {
  displayName = '任务'
  modelName = 'Task'
  name = 'TaskModel'

  index (params: { page: number }) {
    return IOService.get('task', params)
  }

  store (params: any) {
    return IOService.post('task', params)
  }

  show (id: number | string) {
    return IOService.get(`task/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`task/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`task/${id}`)
  }
}
