import areaOptions from '@/pk/assets/json/area'

export interface OptionItem {
  display_name: string;
  value: number;
  [key: string]: string | number;
}

export class OptionService {
  getBoolOptions () {
    return [
      {
        value: 0,
        display_name: '否'
      },
      {
        value: 1,
        display_name: '是'
      }
    ]
  }

  getColor (name: string) {
    switch (name) {
      case 'success':
        return '#67C23A'
      case 'warning':
        return '#E6A23C'
      case 'error':
        return '#F56C6C'
      case 'click':
        return '#409EFF'
      default:
        if (name && name.includes('#')) {
          return name
        } else {
          return '#303133'
        }
    }
  }

  getAreaOptions () {
    return JSON.parse(JSON.stringify(areaOptions))
  }
}

export default new OptionService()
