import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

interface RewardItem {
  coupon_template_id: string;
  amount: number;
  give_number: number;
  expiry_day: number;
  is_stack: number;
}

export default class UserModel extends BaseModel {
  displayName = '会员'
  modelName = 'User'
  name = 'UserModel'

  index (params: { page: number }) {
    return IOService.get('user', params)
  }

  show (id: number | string) {
    return IOService.get(`user/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`user/${params.id}`, params)
  }

  addCoupon (params: { rewards: RewardItem[], user_id: number }) {
    return IOService.post('user/api/addCoupon', params)
  }
}
