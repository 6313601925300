import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class TaskRecordModel extends BaseModel {
  displayName = '任务记录'
  modelName = 'TaskRecord'
  name = 'TaskRecordModel'

  index (params: { page: number }) {
    return IOService.get('task_record', params)
  }
}
