import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class TermModel extends BaseModel {
  displayName = '协议'
  modelName = 'Term'
  name = 'TermModel'

  index (params: { page: number }) {
    return IOService.get('term', params)
  }

  store (params: any) {
    return IOService.post('term', params)
  }

  show (id: number | string) {
    return IOService.get(`term/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`term/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`term/${id}`)
  }
}
