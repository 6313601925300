<template>
  <router-view/>
</template>

<style lang="scss">
body {
  margin: 0;
}
:focus-visible {
  outline: none;
}
</style>
