import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class UserControlModel extends BaseModel {
  displayName = '功能开关'
  modelName = 'UserControl'
  name = 'UserControlModel'

  show (id: number | string) {
    return IOService.get(`user_control/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`user_control/${params.id}`, params)
  }
}
