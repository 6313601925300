import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import member from './member'
import admin from './admin'
import operation from './operation'
import article from './article'
import config from './config'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/pk/views/main.vue'),
    children: [
      ...article,
      ...member,
      ...admin,
      ...operation,
      ...config
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
