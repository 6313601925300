class InfoCheckService {
  public form = {} as { [key: string]: any }

  setForm (name: string, params: any) {
    this.form[name] = params
  }

  removeForm (name: string) {
    this.form[name] = null
  }
}

export default new InfoCheckService()
