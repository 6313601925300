
import InfoCheckService from '@/pk/service/InfoCheckService'
import { PromiseResult } from '@/pk/service/IOService'
import ModelService from '@/pk/service/ModelService'
import RouterService from '@/pk/service/RouterService'
import { defineComponent } from 'vue'
import ModalAbstract from '../Modal/ModalAbstract'

export default defineComponent({
  props: {
    form: {
      type: Object,
      required: true
    },
    submit: Function,
    afterSubmit: {
      type: Function,
      default: (res: PromiseResult) => Promise.resolve(res)
    },
    load: Function,
    afterLoad: {
      type: Function,
      default: () => Promise.resolve()
    },
    beforeLoad: {
      type: Function,
      default: () => Promise.resolve()
    },
    beforeSubmit: {
      type: Function,
      default: () => Promise.resolve()
    },
    disableSubmitAndBack: {
      type: Boolean,
      default: false
    },
    disableFooter: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String,
      default: '115px'
    },
    modalService: {
      type: Object as () => ModalAbstract
    },
    width: {
      type: String,
      default: '50%'
    },
    model: String
  },
  provide () {
    return {
      FormRender: {
        form: this.form,
        validateField: this.validateField,
        model: this.model
      }
    }
  },
  data () {
    return {
      isShowSubmitValidateError: false,
      inDialog: !!this.modalService,
      FormElement: null as any,
      modelInstance: null as any,
      innerWidth: this.width
    }
  },
  computed: {
    drElement (): any {
      return this.$refs.drElement
    },
    inCheckForm () {
      return this.model && InfoCheckService.form[this.model]
    }
  },
  methods: {
    init () {
      this.initForm()
      return Promise.resolve()
        .then(() => {
          if (this.model) {
            this.modelInstance = ModelService[this.model]
          }
        })
    },
    initForm () {
      if (!this.form.id && !this.inCheckForm) {
        this.form.id = RouterService.query('id')
      }
    },
    handleLoad () {
      return this.init()
        .then(() => this.beforeLoad())
        .then(() => {
          if (this.load) {
            return this.load()
          } else {
            return this.__getCheckData()
              .then((res) => {
                if (!res) {
                  return this.__getInstanceData()
                }
                return res
              })
              .then((res: PromiseResult) => {
                if (res) {
                  Object.keys(this.form).forEach((key: string) => {
                    if (!(res.data[key] === null || res.data[key] === undefined)) {
                      this.form[key] = res.data[key]
                    }
                  })
                }
                return res
              })
          }
        })
        .then((res: PromiseResult) => this.afterLoad(res))
    },
    __getCheckData () {
      return Promise.resolve()
        .then(() => {
          if (this.inCheckForm) {
            this.innerWidth = '100%'
            return { data: InfoCheckService.form[this.model as string] }
          }
        })
    },
    __getInstanceData () {
      return Promise.resolve()
        .then(() => {
          if (this.form.id && this.modelInstance && this.modelInstance.show) {
            return this.modelInstance.show(this.form.id)
          }
        })
    },
    handleSuccess () {
      this.FormElement = this.$refs.FormElement
      this.$emit('success')
    },
    handleSubmitAndBack () {
      return this.validate()
        .then(() => this.handleSubmit())
        .then((res: PromiseResult) => {
          if (!this.disableSubmitAndBack) {
            if (this.modalService) {
              this.modalService.success(res)
            } else {
              RouterService.go()
              return res
            }
          }
        })
    },
    handleReset () {
      this.FormElement.resetFields()
      this.isShowSubmitValidateError = false
      this.$emit('formReset')
    },
    validate () {
      return this.FormElement.validate()
        .then(() => {
          this.isShowSubmitValidateError = false
        })
        .catch((err: any) => {
          this.isShowSubmitValidateError = true
          throw err
        })
    },
    validateField (props: string | string[]) {
      this.FormElement.validateField(props)
        .catch((err: any) => console.log(err))
    },
    reload () {
      return this.drElement.reload()
    },
    handleSubmit () {
      return this.validate()
        .then(() => this.beforeSubmit())
        .then(() => {
          if (this.submit) {
            return this.submit()
          } else {
            if (this.modelInstance) {
              if (this.form.id) {
                return this.modelInstance.update(this.form)
              } else {
                return this.modelInstance.store({
                  ...this.form,
                  marking: RouterService.query('marking')
                })
              }
            }
          }
        })
        .then((res: PromiseResult) => {
          if (this.inCheckForm) {
            InfoCheckService.removeForm(this.model as string)
          }
          return this.afterSubmit(res)
        })
    }
  }
})
