import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class NoticeModel extends BaseModel {
  displayName = '公告'
  modelName = 'Notice'
  name = 'NoticeModel'

  index (params: { page: number }) {
    return IOService.get('notice', params)
  }

  store (params: any) {
    return IOService.post('notice', params)
  }

  show (id: number | string) {
    return IOService.get(`notice/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`notice/${params.id}`, params)
  }

  destroy (id: number | string) {
    return IOService.delete(`notice/${id}`)
  }
}
