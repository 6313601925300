import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    modalElement (): any {
      return this.$refs.modalElement
    }
  },
  methods: {
    close (res: any) {
      this.modalElement.close(res)
    },
    success (res: any) {
      this.modalElement.success(res)
    }
  }
})
