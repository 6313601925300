
import { defineComponent } from 'vue'
import TableMixins from './TableMixins'

export default defineComponent({
  mixins: [TableMixins],
  inject: ['TableRender'],
  props: {
    width: {
      type: Number,
      default: 95
    }
  }
})
