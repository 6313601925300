import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.field.label,
    prop: _ctx.field.prop,
    rules: _ctx.field.rule,
    class: "FormMultipleCascader"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_cascader, {
        modelValue: _ctx.innerValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        clearable: true,
        options: _ctx.field.options,
        props: _ctx.props,
        "collapse-tags": "",
        "collapse-tags-tooltip": "",
        filterable: true,
        disabled: _ctx.field.disabled,
        "popper-class": "FormMultipleCascader-popup",
        onChange: _ctx.handleChange
      }, null, 8, ["modelValue", "options", "props", "disabled", "onChange"])
    ]),
    _: 1
  }, 8, ["label", "prop", "rules"]))
}