import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "FormGroup-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_FormText = _resolveComponent("FormText")!
  const _component_ButtonDelete = _resolveComponent("ButtonDelete")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_FormRender = _resolveComponent("FormRender")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_DataRender = _resolveComponent("DataRender")!

  return (_openBlock(), _createBlock(_component_DataRender, {
    load: _ctx.load,
    class: "FormGroupPopup",
    onSuccess: _ctx.handleLoadSuccess
  }, {
    default: _withCtx(() => [
      (_ctx.isLoadComplete)
        ? (_openBlock(), _createBlock(_component_el_card, { key: 0 }, {
            header: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_row, {
                  justify: "space-between",
                  align: "middle"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.title), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 8,
                      class: "header-action"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ButtonSubmit, { click: _ctx.createGroup }, {
                          default: _withCtx(() => [
                            _createTextVNode("添加")
                          ]),
                          _: 1
                        }, 8, ["click"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_el_row, { gutter: 60 }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerValue, (v, index) => {
                    return (_openBlock(), _createBlock(_component_el_col, {
                      span: 24 / _ctx.col,
                      key: index,
                      class: "FormGroup-col"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
                          return (_openBlock(), _createBlock(_component_FormText, {
                            "label-width": "95px",
                            modelValue: _ctx.getValue(v, field),
                            field: field,
                            key: field.prop
                          }, null, 8, ["modelValue", "field"]))
                        }), 128)),
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_ButtonSubmit, {
                            size: "small",
                            click: () => _ctx.handleEdit(index)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("编辑")
                            ]),
                            _: 2
                          }, 1032, ["click"]),
                          _createVNode(_component_ButtonDelete, {
                            size: "small",
                            type: "danger",
                            click: () => _ctx.handleDelete(index)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("删除")
                            ]),
                            _: 2
                          }, 1032, ["click"])
                        ])
                      ]),
                      _: 2
                    }, 1032, ["span"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isShowDialog)
        ? (_openBlock(), _createBlock(_component_el_dialog, {
            key: 1,
            modelValue: _ctx.isShowDialog,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isShowDialog) = $event)),
            "close-on-click-modal": false,
            class: "FormGroupDialog",
            "append-to-body": true,
            "destroy-on-close": true,
            onClose: _ctx.handleClose,
            width: "600px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FormRender, {
                form: _ctx.innerForm,
                submit: _ctx.handleSubmit,
                disableSubmitAndBack: true,
                width: "100%"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", { v: _ctx.innerForm })
                ]),
                _: 3
              }, 8, ["form", "submit"])
            ]),
            _: 3
          }, 8, ["modelValue", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["load", "onSuccess"]))
}