
import { OptionItem } from '@/pk/service/OptionService'
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'
import ToolService from '@/pk/service/ToolService'

export default defineComponent({
  mixins: [FormMixins],
  data () {
    return {
      props: {
        value: 'value',
        label: 'display_name'
      },
      options: [] as OptionItem[],
      loading: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      Object.assign(this.props, this.field.props)
      this.innerValue = this.innerValue || ''
      if (this.field.options) {
        this.options = ToolService.checkOptionObjToArray(this.field.options)
      }
    },
    onChange (value: number | string) {
      this.$emit('change', value)
    }
  }
})
