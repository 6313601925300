
import ToolService from '@/pk/service/ToolService'
import { defineComponent } from 'vue'
import TableMixins from './TableMixins'
import ModalCarouselService from '@/pk/components/Modal/ModalCarouselService'

export default defineComponent({
  mixins: [TableMixins],
  inject: ['TableRender'],
  data () {
    return {
      innerValue: [],
      ModalCarouselService: new ModalCarouselService()
    }
  },
  methods: {
    handleOpenImages (row: any) {
      const images = this.getValue(row)
      return Promise.resolve()
        .then(() => {
          if (images.length > 0) {
            return this.ModalCarouselService.open({ images })
          }
        })
    },
    getValue (row: any) {
      return ToolService.getDeepValue(this.prop, row) || []
    }
  }
})
