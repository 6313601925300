import CacheService from './CacheService'
import IOService from './IOService'

interface LoginParams {
  username: string;
  password: string;
}

interface UserRoleItem {
  id: number;
  display_name: string;
  name: string;
  is_disable_role: number;
  guard_name: string;
  interface_permissions: string[];
  menu_permissions: string[];
}

interface UserInfo {
  id: string;
  username: string;
  nickname: string;
  phone: string;
  token: string;
  user_roles: UserRoleItem[];
}

class AuthService {
  info: UserInfo = {
    id: '',
    nickname: '',
    username: '',
    phone: '',
    token: '',
    user_roles: [] as UserRoleItem[]
  }

  constructor () {
    Object.assign(this.info, CacheService.user.getAll())
  }

  login (params: LoginParams) {
    return this.getToken(params)
      .then(() => IOService.post('auth/login', {}))
      .then((res) => {
        this.updateData({ user_roles: res.data.user_roles, ...res.data.user })
      })
  }

  logout () {
    return Promise.resolve()
      .then(() => {
        CacheService.clearAll()
        return '登出成功'
      })
  }

  update () {
    return IOService.post('auth/update', {})
      .then((res) => {
        this.updateData({ user_roles: res.data.user_roles, ...res.data.user })
      })
  }

  private getToken (params: LoginParams) {
    return IOService.post('auth/token', params)
      .then((res) => {
        this.updateData(res.data)
      })
  }

  public updateData (params: UserInfo) {
    CacheService.user.setAll(params)
    Object.assign(this.info, CacheService.user.getAll())
  }

  // name = XXXControler@store
  hasPermission (name: string) {
    if (this.hasRoot()) {
      return true
    }
    const isDisableMenu = this.info.user_roles
      .filter((res) => res.is_disable_role)
      .map((res) => res.interface_permissions)
      .flat()
      .includes(name)
    if (isDisableMenu) {
      return false
    }
    return this.info.user_roles
      .filter((res) => !res.is_disable_role)
      .map((res) => res.interface_permissions)
      .flat()
      .includes(name)
  }

  hasMenu (name: string) {
    if (this.hasRoot()) {
      return true
    }
    const isDisableMenu = this.info.user_roles
      .filter((res) => res.is_disable_role)
      .map((res) => res.menu_permissions)
      .flat()
      .includes(name)
    if (isDisableMenu) {
      return false
    }
    return this.info.user_roles
      .filter((res) => !res.is_disable_role)
      .map((res) => res.menu_permissions)
      .flat()
      .includes(name)
  }

  hasRoot () {
    return this.info.user_roles.some((res) => res.name === 'root')
  }
}

export default new AuthService()
