import { FolderChecked, Tickets } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

export default [
  {
    path: '/article',
    component: () => import('@/pk/views/sider.vue'),
    meta: {
      title: '文章管理'
    },
    children: [
      {
        path: '/article/info-check',
        component: () => import('@/views/info/info-check/index.vue'),
        meta: {
          title: '文章审核',
          icon: markRaw(FolderChecked),
          query: {
            infoModelName: 'Article'
          }
        },
        children: [
          {
            path: '/article/info-check/form',
            component: () => import('@/views/info/info-check/form.vue'),
            meta: {
              title: '审核详情',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/article/manage',
        component: () => import('@/views/article/index.vue'),
        meta: {
          title: '文章管理',
          icon: markRaw(Tickets)
        },
        children: [
          {
            path: '/article/manage/form',
            component: () => import('@/views/article/form.vue'),
            meta: {
              title: '文章详情',
              isForm: true
            }
          }
        ]
      }
    ]
  }
]
