import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class UserEnterpriseModel extends BaseModel {
  displayName = '企业信息'
  modelName = 'UserEnterprise'
  name = 'UserEnterpriseModel'

  show (id: number | string) {
    return IOService.get(`user_enterprise/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`user_enterprise/${params.id}`, params)
  }
}
