import InitService from '@/pk/service/InitService'

export default abstract class ModalAbstract {
  public id!: number
  public abstract name: string
  protected component!: any
  private resolve!: Function
  private reject!: Function
  public AppService!: InitService | null

  constructor () {
    this.id = new Date().getTime()
  }

  public init (props: any): Promise<any> {
    return Promise.resolve()
      .then(() => {
        this.AppService = (new InitService(this.component, {
          ...props,
          onCancel: (res: any) => {
            this.cancel(res)
          },
          onSuccess: (res: any) => {
            this.success(res)
          }
        })).createComponent()
      })
  }

  public open (props?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.init(props)
        .then(() => {
          this.resolve = resolve
          this.reject = reject
        })
    })
  }

  public destroy (): void {
    setTimeout(() => {
      this.AppService && this.AppService.destroy()
      this.AppService = null
    }, 300)
  }

  public success (res: { [key: string]: any }): void {
    this.resolve && this.resolve(res)
    this.destroy()
  }

  public cancel (res?: { [key: string]: any }): void {
    this.reject && this.reject(res || {})
    this.destroy()
  }
}
