import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableButtons = _resolveComponent("TableButtons")!
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(_component_el_table_column, {
    prop: _ctx.prop,
    label: _ctx.label,
    width: _ctx.width,
    "min-width": _ctx.minWidth,
    fixed: _ctx.fixed,
    "class-name": "TableAction"
  }, {
    default: _withCtx((scope) => [
      _createVNode(_component_TableButtons, {
        buttons: _ctx.buttons,
        row: scope.row,
        onRefresh: _ctx.handleRefresh
      }, null, 8, ["buttons", "row", "onRefresh"])
    ]),
    _: 1
  }, 8, ["prop", "label", "width", "min-width", "fixed"]))
}