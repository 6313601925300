
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'

export default defineComponent({
  mixins: [FormMixins],
  props: {
    activeText: {
      type: String,
      default: '开启'
    },
    inactiveText: {
      type: String,
      default: '关闭'
    }
  }
})
