
import { defineComponent } from 'vue'
import ModalFileManageService from '../Modal/ModalFileManageService'
import FormMixins from './FormMixins'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueNeditorWrap from 'vue-neditor-wrap-wx'
import UIService from '@/pk/service/UIService'

export default defineComponent({
  mixins: [FormMixins],
  props: {
    height: {
      type: Number,
      default: 240
    }
  },
  components: {
    VueNeditorWrap
  },
  data () {
    return {
      editor: null as any,
      editorConfig: {
        // 你的UEditor资源存放的路径,相对于打包后的index.html
        UEDITOR_HOME_URL: '/NEditor/',
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: this.height,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 关闭自动保存
        enableAutoSave: false,
        toolbars: [
          [
            'fullscreen',
            'source',
            'undo',
            'redo',
            'bold',
            'italic',
            'underline',
            'fontborder',
            'strikethrough',
            'removeformat',
            'searchreplace',
            'autotypeset',
            'blockquote',
            'pasteplain',
            'forecolor',
            'backcolor',
            'insertorderedlist',
            'insertunorderedlist',
            'lineheight',
            'fontsize',
            'indent',
            'justifyleft',
            'justifycenter',
            'justifyright',
            'justifyjustify',
            'insertimage',
            'insertimages',
            'horizontal'
          ]
        ]
      },
      ModalFileManageService: new ModalFileManageService()
    }
  },
  computed: {
    NEditorElement (): any {
      return this.$refs.NEditorElement
    }
  },
  created () {
    this.innerValue = this.innerValue || ''
  },
  methods: {
    handleChangeModal () {
      return this.ModalFileManageService.open({ type: 'multiple' })
        .then((data: object[]) => {
          const imgs = data.map((res: any) => res.full_url)
            .map((img: string) => '<p><img src="' + img + '" /></p>')
            .join('')
          this.editor.execCommand('inserthtml', imgs)
        })
        .catch((err) => UIService.toast.error(err.message))
    },
    editorReady (editor: any) {
      this.editor = editor
    },
    editorBeforeInit () {
      this.NEditorElement.registerButton({
        name: 'insertimages',
        icon: require('@/pk/assets/images/editor-images.png'),
        tip: '图片上传',
        handler: () => this.handleChangeModal()
      })
    },
    handleInput (text: string) {
      this.innerValue = text
    }
  }
})
