import { OptionService } from '@/pk/service/OptionService'

declare module '@/pk/service/OptionService' {
  interface OptionService {
    CaptchaReason: { '登录': 1, '注册': 2, '绑定手机号': 3, '重置手机号': 4, '个人认证': 5, '企业认证': 6 }
    Blood: { 'A型': 1, 'B型': 2, 'AB型': 3, 'O型': 4, 'A型RH阴性': 5, 'B型RH阴性': 6, 'AB型RH阴性': 7, 'O型RH阴性': 8, '其它稀有血型': 9 }
    CompanyScale: { '10人以下': 1, '10~50人': 2, '50~100人': 3, '100~500人': 4, '500~1000人': 5, '1000~3000人': 6, '3000~5000人': 7, '5000人以上': 8 }
    PayStatus: { '未支付': 1, '已支付': 2, '支付失败': 3, '取消支付': 4, '支付超时': 5, '已退款': 6 }
    UserCouponStatus: { '未使用': 1, '使用中': 2, '已使用': 3, '已过期': 4 }
    UserAuthStatus: { '审核中': 1, '已通过': 2, '已拒绝': 3 }
    UserCashStatus: { '申请中': 1, '已通过': 2, '已拒绝': 3, '已撤回': 4, '已转款': 5 }
    InfoCheckStatus: { '审核中': 1, '已通过': 2, '已拒绝': 3, '已失效': 4 }
    Payment: { '线上支付': 1, '线下支付': 2, '余额支付': 3, '积分支付': 4, '优惠券支付': 5, '免费支付': 6 }
    OnlinePayment: { '微信公众号支付': 1, '微信小程序支付': 2, '微信H5支付': 3, '微信扫码支付': 4, '微信APP支付': 5, '抖音小程序支付': 6, '支付宝小程序支付': 7, '支付宝H5支付': 8, '支付宝扫码支付': 9, '支付宝APP支付': 10 }
    Client: { '微信公众号': 1, '微信小程序': 2, 'H5': 3, 'PC': 4, '抖音小程序': 5, '支付宝小程序': 6, 'APP': 7 }
    AdPosition: { '头部Banner': 1 }
    NoticeSendType: { '所有人': 1, '仅会员': 2 }
  }
}

OptionService.prototype.CaptchaReason = { '登录': 1, '注册': 2, '绑定手机号': 3, '重置手机号': 4, '个人认证': 5, '企业认证': 6 }
OptionService.prototype.Blood = { 'A型': 1, 'B型': 2, 'AB型': 3, 'O型': 4, 'A型RH阴性': 5, 'B型RH阴性': 6, 'AB型RH阴性': 7, 'O型RH阴性': 8, '其它稀有血型': 9 }
OptionService.prototype.CompanyScale = { '10人以下': 1, '10~50人': 2, '50~100人': 3, '100~500人': 4, '500~1000人': 5, '1000~3000人': 6, '3000~5000人': 7, '5000人以上': 8 }
OptionService.prototype.PayStatus = { '未支付': 1, '已支付': 2, '支付失败': 3, '取消支付': 4, '支付超时': 5, '已退款': 6 }
OptionService.prototype.UserCouponStatus = { '未使用': 1, '使用中': 2, '已使用': 3, '已过期': 4 }
OptionService.prototype.UserAuthStatus = { '审核中': 1, '已通过': 2, '已拒绝': 3 }
OptionService.prototype.UserCashStatus = { '申请中': 1, '已通过': 2, '已拒绝': 3, '已撤回': 4, '已转款': 5 }
OptionService.prototype.InfoCheckStatus = { '审核中': 1, '已通过': 2, '已拒绝': 3, '已失效': 4 }
OptionService.prototype.Payment = { '线上支付': 1, '线下支付': 2, '余额支付': 3, '积分支付': 4, '优惠券支付': 5, '免费支付': 6 }
OptionService.prototype.OnlinePayment = { '微信公众号支付': 1, '微信小程序支付': 2, '微信H5支付': 3, '微信扫码支付': 4, '微信APP支付': 5, '抖音小程序支付': 6, '支付宝小程序支付': 7, '支付宝H5支付': 8, '支付宝扫码支付': 9, '支付宝APP支付': 10 }
OptionService.prototype.Client = { '微信公众号': 1, '微信小程序': 2, 'H5': 3, 'PC': 4, '抖音小程序': 5, '支付宝小程序': 6, 'APP': 7 }
OptionService.prototype.AdPosition = { '头部Banner': 1 }
OptionService.prototype.NoticeSendType = { '所有人': 1, '仅会员': 2 }
