import config from '@/config/config'

interface AppEnv {
  commonHost: string // 公共资源域名
  pageHost: string // 页面域名
  imgHost: string // 图片域名
  apiHost: string // 接口域名
  env: string // 开发/测试/生产
}

class AppService {
  public appEnv = {
    commonHost: 'https://common-media-resource.oss-cn-shenzhen.aliyuncs.com',
    pageHost: '',
    imgHost: '',
    apiHost: '',
    env: ''
  } as AppEnv

  constructor () {
    this.initAppEnv()
  }

  private initAppEnv () {
    this.appEnv.env = this.getEnv()
    this.appEnv.pageHost = this.getPageHost()
    this.appEnv.imgHost = this.getImgHost()
    this.appEnv.apiHost = this.getApiHost()
  }

  private getApiHost () {
    if (this.appEnv.env === 'dev') {
      return 'http://api-dev.' + config.apiDomain
    } else if (this.appEnv.env === 'test') {
      return 'https://api-test.' + config.apiDomain
    } else {
      return 'https://api.' + config.apiDomain
    }
  }

  private getPageHost () {
    if (this.appEnv.env === 'dev') {
      return 'http://admin-dev.' + config.pageDomain
    } else if (this.appEnv.env === 'test') {
      return 'https://admin-test.' + config.pageDomain
    } else {
      return 'https://admin.' + config.pageDomain
    }
  }

  private getImgHost () {
    if (this.appEnv.env === 'dev') {
      return 'http://api-dev.' + config.imageDomain
    } else if (this.appEnv.env === 'test') {
      return 'https://img-test.' + config.imageDomain
    } else {
      return 'https://img.' + config.imageDomain
    }
  }

  private getEnv () {
    if (location.host.includes('-test.')) {
      return 'test'
    }
    if (location.host.includes('-dev.')) {
      return 'dev'
    }
    return 'prod'
  }

  public resolvePublicResource (path: string, style?: string) {
    return `${this.appEnv.commonHost}${path}${style ? '!' + style : ''}`
  }
}

export default new AppService()
