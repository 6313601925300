import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    prop: {
      type: String,
      default: '_action'
    },
    label: {
      type: String,
      default: '操作'
    },
    width: Number,
    minWidth: Number,
    align: String,
    className: String,
    props: {
      type: Object,
      default: () => {
        return {
          label: 'display_name',
          value: 'value'
        }
      }
    }
  }
})
