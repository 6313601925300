import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class CouponTemplateModel extends BaseModel {
  displayName = '优惠券模板'
  modelName = 'CouponTemplate'
  name = 'CouponTemplateModel'

  index (params: { page: number }) {
    return IOService.get('coupon_template', params)
  }

  show (id: number | string) {
    return IOService.get(`coupon_template/${id}`)
  }

  update (params: { id: number }) {
    return IOService.put(`coupon_template/${params.id}`, params)
  }

  all () {
    return IOService.get('coupon_template/api/all')
  }
}
