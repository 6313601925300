
import OptionService from '@/pk/service/OptionService'
import IOService from '@/pk/service/IOService'
import ValidateService from '@/pk/service/ValidateService'
import { defineComponent } from 'vue'
import SearchMixins from './SearchMixins'

export default defineComponent({
  mixins: [SearchMixins],
  props: {
    options: Array,
    optionsProps: Object,
    type: String,
    url: String
  },
  data () {
    return {
      props: {
        expandTrigger: 'hover',
        label: 'display_name',
        value: 'id',
        multiple: true,
        emitPath: false
      },
      field: ValidateService.genRule({
        prop: 'value',
        label: '值',
        rule: [ValidateService.required({ trigger: 'change' })]
      }),
      innerUrl: '',
      innerOptions: this.options || []
    }
  },
  created () {
    this.handleLoad()
      .then(() => {
        Object.assign(this.props, this.optionsProps)
      })
  },
  methods: {
    handleLoad () {
      return Promise.resolve()
        .then(() => {
          if (this.type === 'industry') {
            this.innerUrl = '/industry'
          }
          if (this.type === 'city') {
            this.field.props = {
              label: 'name',
              value: 'id'
            }
            return OptionService.getAreaOptions()
          } else if (this.innerUrl) {
            return IOService.get(this.innerUrl)
              .then((res: any) => res.data || [])
          }
        })
        .then((data) => {
          this.innerOptions = data
        })
    }
  }
})
