
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'

interface InnerRule {
  required?: boolean;
  validator?: Function;
  message?: string;
}

export default defineComponent({
  mixins: [FormMixins],
  props: {
    minValue: {
      type: [String, Number]
    },
    maxValue: {
      type: [String, Number]
    },
    isNegotiable: {
      type: Number
    }
  },
  watch: {
    innerMinValue (val) {
      this.$emit('update:minValue', val)
      this.formItemElement.clearValidate()
    },
    innerMaxValue (val) {
      this.$emit('update:maxValue', val)
      this.formItemElement.clearValidate()
    },
    minValue (val) {
      this.innerMinValue = val
      this.formItemElement.clearValidate()
    },
    maxValue (val) {
      this.innerMaxValue = val
      this.formItemElement.clearValidate()
    },
    innerIsNegotiable (val) {
      this.$emit('update:isNegotiable', val)
      this.formItemElement.clearValidate()
    },
    isNegotiable (val) {
      this.innerIsNegotiable = val
      this.formItemElement.clearValidate()
    }
  },
  computed: {
    formItemElement (): any {
      return this.$refs.formItemElement
    }
  },
  data () {
    return {
      innerMinValue: this.minValue,
      innerMaxValue: this.maxValue,
      innerIsNegotiable: this.isNegotiable,
      innerRules: [{ validator: this.validateSalary }] as InnerRule[]
    }
  },
  created () {
    const isRequired = (this.field.rule || []).find((res) => res.required)
    if (isRequired) {
      this.innerRules = this.innerRules.concat([{ required: true, message: `请输入${this.field.label}` }]).concat([{ validator: this.validateSalaryRequired }])
    }
    this.field.rule = this.innerRules
  },
  methods: {
    validateSalary (rule: any, value: number, callback: Function) {
      if (this.innerMinValue || this.innerMaxValue) {
        if (Number(this.innerMinValue) > Number(this.innerMaxValue)) {
          const text = `请正确输入${this.field.label}`
          callback(text)
        }
      }
      callback()
    },
    validateSalaryRequired (rule: any, value: number, callback: Function) {
      if (Number(this.innerIsNegotiable) > 0 || (Number(this.innerMinValue) >= 0 && Number(this.innerMaxValue) > 0)) {
        callback()
      } else {
        const text = `请输入${this.field.label}`
        callback(text)
      }
    }
  }
})
