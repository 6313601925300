import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class UserBillModel extends BaseModel {
  displayName = '账单'
  modelName = 'UserBill'
  name = 'UserBillModel'
  index (params: { userId?: number; page: number }) {
    return IOService.get('user_bill', params)
  }
}
