
import { OptionItem } from '@/pk/service/OptionService'
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'
import ToolService from '@/pk/service/ToolService'

export default defineComponent({
  mixins: [FormMixins],
  data () {
    return {
      props: {
        value: 'value',
        label: 'display_name'
      },
      options: [] as OptionItem[]
    }
  },
  created () {
    Object.assign(this.props, this.field.props)
    if (this.field.options) {
      this.options = ToolService.checkOptionObjToArray(this.field.options)
    }
  }
})
