import IOService from '@/pk/service/IOService'
import { BaseModel } from '../BaseModel'

export default class AdminLogModel extends BaseModel {
  displayName = '后台日志'
  modelName = 'AdminLog'
  name = 'AdminLogModel'

  index (params: { page: number }) {
    return IOService.get('admin_log', params)
  }

  show (id: string | number) {
    return IOService.get(`admin_log/${id}`)
  }
}
