import { Router } from 'vue-router'
import QueryString from 'qs'

class RouterSerivce {
  router!: Router

  init (router: Router) {
    this.router = router
  }

  push (path: string, query: object = {}) {
    if (path === this.getPath()) {
      return
    }
    this.router.push({
      path,
      query: {
        ...query
      }
    })
  }

  replace (path: string, query?: object) {
    if (path === '') {
      path = this.getPath()
    }
    const urlQuery = this.getRouteQuery()
    const currentQuery: { [key: string]: any } = {
      ...query
    }
    if (path === this.getPath() && JSON.stringify(urlQuery) === JSON.stringify(currentQuery)) {
      return
    }
    this.router.replace({
      path,
      query: currentQuery
    })
  }

  pushForm (query: { id?: string | number; marking?: number; [key: string]: any } = {}) {
    if (!query.id) {
      query.marking = (new Date()).valueOf()
    }
    this.push(this.getPath() + '/form', {
      ...this.getRouteQuery(),
      ...query
    })
  }

  go (step = -1) {
    this.router.go(step)
  }

  query (name: string, defaultValue = '') {
    const hash = location.hash.split('?')
    if (hash.length > 1) {
      const obj = QueryString.parse(hash[1])
      return obj[name] as string || defaultValue
    } else {
      return defaultValue
    }
  }

  getPath () {
    const hash = location.hash.split('#').filter((res) => res)
    if (hash.length === 0) {
      return '/'
    }
    const paths = hash[0].split('?')
    return paths[0]
  }

  getRouteQuery () {
    const hash = location.hash.split('?')
    if (hash[1]) {
      return QueryString.parse(hash[1])
    } else {
      return {}
    }
  }

  getSearchQueryString (params: { [key: string]: any } = {}) {
    return QueryString.stringify(Object.assign({}, this.getSearchQuery(), params))
  }

  getSearchQuery (key?: string) {
    const search = QueryString.parse(this.query('_search'))
    if (key) {
      return search[key]
    } else {
      return search
    }
  }
}

export default new RouterSerivce()
