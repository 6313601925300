
import { defineComponent } from 'vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

export default defineComponent({
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    customClass: String
  },
  data () {
    return {
      zhCn,
      innerParams: {
        top: '10vh',
        width: '800px',
        hideCloseBtn: false
      },
      isShow: true
    }
  },
  created () {
    Object.assign(this.innerParams, this.params)
  },
  methods: {
    close (res: any) {
      this.isShow = false
      this.$emit('cancel', res)
    },
    success (res: any) {
      this.isShow = false
      this.$emit('success', res)
    }
  }
})
